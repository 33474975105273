<template>
  <div class="mx-2 mb-2">
    <b-row class="align-items-center">
      <!-- Peg page selector -->
      <b-col>
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-2">
            {{ $t('page.admin_students.table.labels.per_page') }}
          </label>
          <b-form-select
            id="perPageSelect"
            v-model="pg.perPage"
            size="sm"
            :options="pg.perPageOptions"
            style="width: 75px"
          />
        </b-form-group>
      </b-col>
      <!-- Pagination -->
      <b-col class="d-flex align-items-center justify-content-end">
        <b-pagination
          v-if="pg.totalCount / pg.perPage > 1"
          v-model="pg.currentPage"
          :total-rows="pg.totalCount"
          :per-page="pg.perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BPagination, BFormGroup, BFormSelect,
} from 'bootstrap-vue'

export default {
  name: 'TableFooter',
  components: {
    BRow,
    BCol,
    BPagination,
    BFormGroup,
    BFormSelect,
  },
  props: {
    pagination: {
      type: Object,
      required: true,
    },
    paginationUpdated: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      pg: this.pagination,
    }
  },
  computed: {
    perPage() {
      return this.pg.perPage
    },
    currentPage() {
      return this.pg.currentPage
    },
  },
  watch: {
    perPage() {
      this.paginationUpdated()
    },
    currentPage() {
      this.paginationUpdated()
    },
  },
}
</script>

<style scoped>

</style>
