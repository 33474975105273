<template>
  <div>
    <b-row>
      <b-col>
        <div class="d-flex align-items-center mb-1">
          <div class="flex-fill" />
          <assign-study-program-modal @updated="getPrograms">
            <template #activator="{show}">
              <b-button
                variant="primary"
                @click="show"
              >
                <feather-icon
                  class="mr-50"
                  icon="PlusIcon"
                />
                <span>Добавить программу</span>
              </b-button>
            </template>
          </assign-study-program-modal>
        </div>

        <b-card
          no-body
          class="mb-0"
        >
          <table-header
            search
            :filter="filter"
            :search-updated="getPrograms"
          />
          <b-table
            ref="teachersTable"
            striped
            :items="studyPrograms"
            responsive
            :fields="tableFields"
            primary-key="id"
            show-empty
            :empty-text="$t('page.students.search.labels.no-items')"
            :sort-by.sync="filter.sortBy"
            :sort-desc.sync="filter.sortDesc"
          >
            <template #cell(studentName)="data">
              <div class="d-flex flex-row justify-content-start align-items-center">
                <store-image
                  v-if="data.item.image_id"
                  :store-id="data.item.image_id"
                  wrapper-class="table-avatar"
                />
                <b-avatar
                  v-else
                  size="25px"
                />
                <span class="d-block ml-1">{{ data.value }}</span>
              </div>
            </template>
            <template #cell(status)>
              <b-progress
                show-progress
                :value="25"
                max="100"
                variant="primary"
                striped
              />
            </template>
            <template #cell(actions)="{item, toggleDetails, detailsShowing}">
              <div class="d-flex flex-row justify-content-end align-items-center">
                <b-button
                  variant="flat-primary"
                  class="btn-icon rounded-circle"
                  size="sm"
                  @click="toggleDetails"
                >
                  <feather-icon
                    size="15"
                    :icon="detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                  />
                </b-button>
                <b-dropdown
                  size="sm"
                  variant="flat-secondary"
                  toggle-class="text-decoration-none btn-icon rounded-circle ml-1"
                  no-caret
                  boundary="viewport"
                >
                  <template #button-content>
                    <feather-icon
                      size="15"
                      icon="MoreVerticalIcon"
                    />
                  </template>
                  <assign-study-program-modal
                    :study-program="item"
                    :student="item.student.length ? item.student[0] : null"
                    @updated="(program) => Object.assign(item, program)"
                  >
                    <template #activator="{show}">
                      <b-dropdown-item
                        @click="show"
                      >
                        <feather-icon
                          class="mr-50"
                          icon="Edit2Icon"
                        />
                        Изменить
                      </b-dropdown-item>
                    </template>
                  </assign-study-program-modal>
                  <b-dropdown-item
                    @click="toggleDetails"
                  >
                    <feather-icon
                      class="mr-50"
                      :icon="detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    />
                    Подробнее
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="deleteProgram(item.id)">
                    <feather-icon
                      class="text-danger mr-50"
                      icon="Trash2Icon"
                    />
                    <span class="text-danger">Удалить</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template #row-details="{item, toggleDetails}">
              <b-card>
                <b-card-title>{{ item.name }}</b-card-title>
                <p v-if="item.description">
                  {{ item.description }}
                </p>
                <div class="d-flex align-items-center mt-1">
                  <assign-study-program-modal
                    :study-program="item"
                    :student="item.student.length ? item.student[0] : null"
                    @updated="(program) => Object.assign(item, program)"
                  >
                    <template #activator="{show}">
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="mr-1"
                        @click="show"
                      >
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        Изменить
                      </b-button>
                    </template>
                  </assign-study-program-modal>
                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="toggleDetails"
                  >
                    Свернуть
                  </b-button>
                </div>
              </b-card>
            </template>
          </b-table>
          <table-footer
            :pagination-updated="getPrograms"
            :pagination="pagination"
          />
        </b-card>
      </b-col>
    </b-row>

    <confirm-modal
      ref="deleteConfirm"
      :yes="'Удалить'"
      color="danger"
      :text="`Выбранная учебная программа и все связанные с ней занятия будут удалены`"
    />
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BTable, BRow, BCol, BButton, BAvatar, BDropdown, BDropdownItem, BDropdownDivider, BProgress,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import StoreImage from '@/components/store/StoreImage.vue'
import TableHeader from '@/components/page-elements/table/TableHeader.vue'
import TableFooter from '@/components/page-elements/table/TableFooter.vue'
import { getUserFullName } from '@/helpers'
import ConfirmModal from '@/components/page-elements/modals/ConfirmModal.vue'
import AssignStudyProgramModal from '../../components/students/AssignStudyProgramModal.vue'

export default {
  name: 'StudyPrograms',
  components: {
    AssignStudyProgramModal,
    ConfirmModal,
    TableFooter,
    TableHeader,
    StoreImage,
    BCard,
    BTable,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCardTitle,
    BProgress,
  },
  data: () => ({
    studyPrograms: [],
    pagination: {
      perPage: 15,
      perPageOptions: [5, 15, 30],
      totalCount: null,
      currentPage: 1,
    },
    filter: {
      search: '',
      sortBy: null,
      sortDesc: false,
    },
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    tableFields() {
      return [
        {
          key: 'name',
          label: 'Название',
          sortable: true,
        },
        {
          key: 'studentName',
          label: 'Ученик',
          sortable: true,
          formatter: (value, key, row) => (row.student?.length ? getUserFullName(row.student[0]) : 'Нет данных'),
        },
        {
          key: 'status',
          label: 'Состояние',
          sortable: false,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ]
    },
    sortBy() {
      if (!this.filter.sortBy) return null
      let { sortBy } = this.filter
      if (this.filter.sortDesc) sortBy = `-${sortBy}`
      else sortBy = `+${sortBy}`
      return sortBy
    },
  },
  watch: {
    sortBy() {
      this.getPrograms()
    },
  },
  async created() {
    await this.getPrograms()
  },
  methods: {
    async getPrograms() {
      const { items, _meta } = await this.$http.get('/study-programs', {
        params: {
          sort: this.sortBy,
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
          teacher_id: this.user.id,
          name: this.filter.search,
          expand: 'student.usersStudent',
        },
      })
      this.pagination.totalCount = _meta.totalCount
      this.studyPrograms = items
    },
    async deleteProgram(id) {
      this.$refs.deleteConfirm.open()
        .then(async promise => {
          if (promise.result) {
            await this.$http.delete(`/study-programs/delete?id=${id}`)
              .then(() => {
                this.getPrograms()
              })
          }
        })
    },
  },
}
</script>
