<template>
  <div class="d-flex flex-column flex-md-row">
    <b-card
      :border-variant="bordered ? 'secondary' : ''"
      class="mb-0"
      :class="{'w-100': fullWidth}"
      :no-body="noBody"
      style="min-width: 250px"
    >
      <div class="d-flex align-items-center">
        <b-avatar
          class="mr-1"
          :src="student.image ? student.image.full_url : null"
        />
        <div>
          <p class="font-medium-2 mb-0 text-truncate">
            {{ fullName }}
          </p>
          <span class="font-small-2">{{ student.phone }}</span>
        </div>
        <div class="flex-fill" />
        <div>
          <b-button
            v-if="removable"
            class="btn-icon ml-1"
            size="sm"
            variant="flat-secondary"
            @click="$emit('remove')"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
      </div>
    </b-card>
    <b-card
      v-if="withHometask"
      border-variant="secondary"
      class="ml-md-1 mt-1 mt-md-0 py-1 px-2 mb-0 shadow-none flex-fill bg-transparent d-flex flex-column justify-content-center"
      no-body
    >
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h4 class="mb-0">
            Домашнее задание
          </h4>
          <span v-if="!assignedHometasks.length">Нет домашнего задания</span>
          <span v-else><span v-if="assignedHometasks[0].order">№ {{ assignedHometasks[0].order }} |</span> {{ assignedHometasks[0].homeTask.name }}</span>
        </div>

        <session-student-hometask-selector
          :session-student-id="sessionStudentId"
          :student-id="student.id"
          :assigned-hometasks="assignedHometasks"
          @onAssign="(sessionHometask) => $emit('onAssignHometask', sessionHometask)"
          @onDetach="() => $emit('onDetachHometask')"
        >
          <template
            v-if="!assignedHometasks.length"
            #activator="{show}"
          >
            <b-button
              variant="flat-primary"
              @click="show"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              Добавить
            </b-button>
          </template>
          <template
            v-else
            #detach="{detach}"
          >
            <b-button
              variant="flat-primary"
              class="mr-50"
              target="_blank"
              :to="{name: 'cabinet.hometasks.task', params: {task_id: assignedHometasks[0].home_task_id}}"
            >
              Открыть
              <feather-icon
                icon="ArrowRightIcon"
                class="ml-50"
              />
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon"
              title="Удалить"
              @click="detach"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </template>
        </session-student-hometask-selector>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BButton, BAvatar } from 'bootstrap-vue'
import { getUserFullName } from '@/helpers'
import SessionStudentHometaskSelector from '@/components/session/editor/students/SessionStudentHometaskSelector.vue'

export default {
  name: 'SessionStudentCard',
  components: {
    SessionStudentHometaskSelector,
    BCard,
    BButton,
    BAvatar,
  },
  props: {
    sessionStudentId: {
      type: Number,
      default: null,
    },
    student: {
      type: Object,
      required: true,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    noBody: {
      type: Boolean,
      default: false,
    },
    withHometask: {
      type: Boolean,
      default: false,
    },
    assignedHometasks: {
      type: Array,
      default: () => [],
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fullName() {
      return getUserFullName(this.student)
    },
  },
}
</script>

<style scoped>

</style>
