var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex align-items-center mb-1"},[_c('div',{staticClass:"flex-fill"}),_c('assign-study-program-modal',{on:{"updated":_vm.getPrograms},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var show = ref.show;
return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":show}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Добавить программу")])],1)]}}])})],1),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('table-header',{attrs:{"search":"","filter":_vm.filter,"search-updated":_vm.getPrograms}}),_c('b-table',{ref:"teachersTable",attrs:{"striped":"","items":_vm.studyPrograms,"responsive":"","fields":_vm.tableFields,"primary-key":"id","show-empty":"","empty-text":_vm.$t('page.students.search.labels.no-items'),"sort-by":_vm.filter.sortBy,"sort-desc":_vm.filter.sortDesc},on:{"update:sortBy":function($event){return _vm.$set(_vm.filter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filter, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"cell(studentName)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-row justify-content-start align-items-center"},[(data.item.image_id)?_c('store-image',{attrs:{"store-id":data.item.image_id,"wrapper-class":"table-avatar"}}):_c('b-avatar',{attrs:{"size":"25px"}}),_c('span',{staticClass:"d-block ml-1"},[_vm._v(_vm._s(data.value))])],1)]}},{key:"cell(status)",fn:function(){return [_c('b-progress',{attrs:{"show-progress":"","value":25,"max":"100","variant":"primary","striped":""}})]},proxy:true},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var toggleDetails = ref.toggleDetails;
var detailsShowing = ref.detailsShowing;
return [_c('div',{staticClass:"d-flex flex-row justify-content-end align-items-center"},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-primary","size":"sm"},on:{"click":toggleDetails}},[_c('feather-icon',{attrs:{"size":"15","icon":detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'}})],1),_c('b-dropdown',{attrs:{"size":"sm","variant":"flat-secondary","toggle-class":"text-decoration-none btn-icon rounded-circle ml-1","no-caret":"","boundary":"viewport"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"size":"15","icon":"MoreVerticalIcon"}})]},proxy:true}],null,true)},[_c('assign-study-program-modal',{attrs:{"study-program":item,"student":item.student.length ? item.student[0] : null},on:{"updated":function (program) { return Object.assign(item, program); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var show = ref.show;
return [_c('b-dropdown-item',{on:{"click":show}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_vm._v(" Изменить ")],1)]}}],null,true)}),_c('b-dropdown-item',{on:{"click":toggleDetails}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'}}),_vm._v(" Подробнее ")],1),_c('b-dropdown-divider'),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteProgram(item.id)}}},[_c('feather-icon',{staticClass:"text-danger mr-50",attrs:{"icon":"Trash2Icon"}}),_c('span',{staticClass:"text-danger"},[_vm._v("Удалить")])],1)],1)],1)]}},{key:"row-details",fn:function(ref){
var item = ref.item;
var toggleDetails = ref.toggleDetails;
return [_c('b-card',[_c('b-card-title',[_vm._v(_vm._s(item.name))]),(item.description)?_c('p',[_vm._v(" "+_vm._s(item.description)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center mt-1"},[_c('assign-study-program-modal',{attrs:{"study-program":item,"student":item.student.length ? item.student[0] : null},on:{"updated":function (program) { return Object.assign(item, program); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var show = ref.show;
return [_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":show}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_vm._v(" Изменить ")],1)]}}],null,true)}),_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":toggleDetails}},[_vm._v(" Свернуть ")])],1)],1)]}}])}),_c('table-footer',{attrs:{"pagination-updated":_vm.getPrograms,"pagination":_vm.pagination}})],1)],1)],1),_c('confirm-modal',{ref:"deleteConfirm",attrs:{"yes":'Удалить',"color":"danger","text":"Выбранная учебная программа и все связанные с ней занятия будут удалены"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }