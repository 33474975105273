<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="dialog"
      title="Назначение учебной программы"
      hide-footer
      centered
      no-body
      size="lg"
      :dialog-class="!studyProgram && !selectedCourse ? 'large-modal' : ''"
      body-class="body-bg p-0"
    >
      <template>
        <div
          v-if="!selectedCourse && !studyProgram"
          class="p-2"
        >
          <h3 class="mb-1">
            Выберите курс (Учебник)
          </h3>

          <b-row style="min-height: 75vh">
            <b-col
              cols="12"
              md="6"
              lg="4"
              xl="3"
            >
              <courses-filter
                class="sticky-top"
                :filter="filter"
                @updated="reloadList"
              />
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="8"
              xl="9"
            >
              <courses-list
                ref="list"
                :filter="filter"
                external
                selectable
                :selected-course="selectedCourse"
                @select="(course) => { selectedCourse = course; studyProgramObject.name = course.name }"
              />
            </b-col>
          </b-row>
        </div>

        <form
          v-else
          @submit.prevent="createSessions"
        >
          <div class="p-2">
            <b-row>
              <b-col>
                <p>Из уроков данного курса будет создан список занятий для выбранного ученика.
                  В занятиях не будет указана дата проведения. Назначить дату и время можно в редакторе занятия</p>
                <h3 class="mb-1">
                  Информация об учебной программе
                </h3>

                <b-form-group>
                  <label>Ученик</label>
                  <div v-if="!student">
                    <v-select
                      v-model="selectedStudent"
                      :options="students"
                      label="username"
                      return-object
                      :clearable="false"
                      placeholder="Выберите ученика"
                      class="form-control-select"
                    >
                      <template #option="user">
                        <div class="d-flex align-items-center">
                          <b-avatar
                            :src="user.image ? user.image.full_url : null"
                            size="32px"
                            rounded
                          />
                          <div class="ml-1">
                            <p class="mb-0">
                              {{ getStudentName(user) }}
                            </p>
                            <span class="font-small-2">{{ user.phone }}</span>
                          </div>
                        </div>
                      </template>
                      <template #selected-option="user">
                        <span>{{ getStudentName(user) }}</span>
                      </template>
                    </v-select>
                    <span
                      v-if="noStudent"
                      class="text-danger"
                    >Необходимо указать ученика</span>
                  </div>
                  <session-student-card
                    v-else
                    :student="student"
                  />
                </b-form-group>

                <b-form-group>
                  <label>Название</label>
                  <b-form-input
                    v-model="studyProgramObject.name"
                    placeholder="Название"
                  />
                </b-form-group>

                <b-form-group>
                  <b-form-textarea
                    v-model="studyProgramObject.description"
                    rows="3"
                    placeholder="Описание"
                  />
                </b-form-group>

                <b-card
                  v-if="selectedCourse"
                  no-body
                >
                  <b-row no-gutters>
                    <b-col cols="3">
                      <course-image
                        class="zindex-2 position-relative"
                        image-height="100%"
                        :full-url="selectedCourse.image ? selectedCourse.image.full_url : null"
                      />
                    </b-col>
                    <b-col>
                      <div class="p-1">
                        <b-card-title>{{ selectedCourse.name }}</b-card-title>
                        <b-card-sub-title
                          v-if="selectedCourse.description"
                          class="mt-0"
                        >
                          {{ selectedCourse.description }}
                        </b-card-sub-title>
                        <b-card-sub-title class="mt-0">
                          <div v-if="selectedCourse.subjects">
                            {{ selectedCourse.subjects.text }}
                            <span v-if="selectedCourse.grade">, </span>
                          </div>
                          <div v-if="selectedCourse.grade">
                            {{ selectedCourse.grade.name }}
                          </div>
                        </b-card-sub-title>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>

          </div>

          <!-- submit button -->
          <div class="thin-divider" />
          <div class="p-2 d-flex align-items-center justify-content-between">
            <b-button
              variant="primary"
              type="submit"
              :disabled="!selectedStudent"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
              />
              <span v-if="!studyProgram">Создать уроки</span>
              <span v-else>Сохранить</span>
            </b-button>
            <div class="flex-fill" />
            <b-button
              variant="flat-secondary"
              type="button"
              @click="dialog = false"
            >
              <span v-if="!studyProgram">{{ $t('components.button.labels.cancel') }}</span>
              <span v-else>Скрыть</span>
            </b-button>
          </div>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle,
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea,
  BModal, BButton, BAvatar,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import CoursesFilter from '../new_courses/CoursesFilter.vue'
import CoursesList from '../new_courses/CoursesList.vue'
import CourseImage from '../new_courses/course/CourseImage.vue'
import { getUserFullName } from '../../helpers'
import SessionStudentCard from '../session/editor/students/SessionStudentCard.vue'

export default {
  name: 'AssignStudyProgramModal',
  components: {
    SessionStudentCard,
    CourseImage,
    CoursesList,
    CoursesFilter,
    BAvatar,
    BModal,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
  },
  props: {
    student: {
      type: Object,
      default: null,
    },
    studyProgram: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    phone: '',
    error: null,
    filter: {
      steps: false,
      own: false,
      types: [],
      subjects: [],
      grades: [],
      tags: [],
    },
    selectedCourse: null,
    studyProgramObject: {
      name: null,
      description: null,
    },
    noStudent: false,
    selectedStudent: null,
    students: [],
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
  },
  mounted() {
    if (this.studyProgram) this.studyProgramObject = { ...this.studyProgram }
    if (this.student) this.selectedStudent = { ...this.student }
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    saveAndClose() {
      this.closeDialog()
    },
    show() {
      this.selectedCourse = null
      this.getStudents()
      this.dialog = !this.dialog
    },
    reloadList() {
      setTimeout(() => {
        this.$refs.list.load()
      })
    },
    async createSessions() {
      this.noStudent = false
      if (!this.selectedStudent) {
        this.noStudent = true
        return
      }

      if (!this.studyProgram) {
        const studyProgram = await this.$http.post('/study-programs/create-tape', {
          name: this.studyProgramObject.name,
          description: this.studyProgramObject.description,
          teacher_id: this.user.id,
          student_ids: [this.selectedStudent.id],
          course_id: this.selectedCourse.id,
        })
        this.studyProgramObject = { ...studyProgram }
      } else {
        const programs = await this.$http.put(`/study-programs/update?id=${this.studyProgram.id}`, { ...this.studyProgramObject })
        this.studyProgramObject = { ...programs[0] }
      }

      this.$emit('updated', this.studyProgramObject)
      this.dialog = false
    },
    async getStudents() {
      const { items } = await this.$http.get('/user/current-teacher-student-index?expand=usersStudent.image')
      this.students = items.filter(item => !item.study_program_id)
    },
    getStudentName(student) {
      return getUserFullName(student)
    },
  },
}
</script>

<style scoped>

</style>
