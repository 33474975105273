<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <slot
      name="detach"
      v-bind="{detach}"
    />
    <b-modal
      v-model="display"
      :title="title"
      hide-footer
      centered
      no-body
      body-class="p-0"
      size="lg"
    >
      <template #default="{ hide }">
        <b-input-group class="p-1">
          <b-input-group-prepend is-text>
            <feather-icon
              icon="SearchIcon"
              class="text-muted"
            />
          </b-input-group-prepend>
          <b-form-input
            v-model="search"
            placeholder="Поиск..."
            @input="!search ? getHometasks() : ''"
            @keydown.enter="getHometasks()"
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
            @click="search = null; getHometasks"
          >
            <feather-icon
              icon="XIcon"
              class="text-muted"
            />
          </b-input-group-append>
        </b-input-group>

        <div
          class="px-1 pb-1 pt-0"
          style="max-height: 70vh; overflow-y: auto"
        >
          <b-card
            v-for="task in tasks"
            :key="task.id"
            :border-variant="selectedTask && selectedTask.id === task.id ? 'primary' : 'secondary'"
            class="mb-50 shadow-none hoverable cursor-pointer"
            @click="selectedTask && selectedTask.id === task.id ? selectedTask = null : selectedTask = task"
          >
            <b-card-title>{{ task.name }}</b-card-title>
            <b-card-sub-title>{{ task.created | dateTime }}</b-card-sub-title>
          </b-card>
        </div>

        <div class="thin-divider" />
        <div class="p-2 d-flex align-items-center justify-content-between">
          <b-button
            variant="primary"
            type="submit"
            :disabled="!selectedTask"
            @click="selectTask"
          >
            Выбрать
          </b-button>
          <b-button
            variant="flat-secondary"
            type="button"
            @click="hide"
          >
            {{ $t('components.button.labels.cancel') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupPrepend, BFormInput, BInputGroupAppend,
  BCard, BCardTitle, BCardSubTitle,
  BButton, BModal,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { HOMETASK_STATUS_INACTIVE } from '../../../../shared/constants'

export default {
  name: 'SessionStudentHometaskSelector',
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    BModal,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BInputGroupAppend,
  },
  props: {
    sessionStudentId: {
      type: Number,
      default: null,
    },
    studentId: {
      type: Number,
      required: true,
    },
    assignedHometasks: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    search: '',
    tasks: [],
    display: false,
    title: 'Выбор домашнего задания',
    selectedTask: null,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
  },
  methods: {
    show() {
      this.display = true
      this.getHometasks()
    },
    async detach() {
      const { id, homeTask } = this.assignedHometasks[0]
      await this.$http.delete(`/session-student-home-tasks/delete?id=${id}`)
      const { id: homeTaskId } = homeTask
      await this.$http.put(`/home-tasks/update?id=${homeTaskId}`, {
        status: HOMETASK_STATUS_INACTIVE,
      })

      this.$emit('onDetach')
    },
    async getHometasks() {
      const { items } = await this.$http.get('/home-tasks', {
        params: {
          name: this.search,
          teacher_id: this.user.id,
        },
      })
      this.tasks = items
    },
    async selectTask() {
      const { id: homeTaskId } = this.selectedTask
      const addedTask = await this.$http.post('/session-student-home-tasks/create', { session_student_id: this.sessionStudentId, home_task_id: homeTaskId }, {
        params: {
          expand: 'homeTask.homeTasksSteps.homeTasksStepElements.image',
        },
      })
      await this.$http.put(`/home-tasks/update?id=${homeTaskId}`, {
        status: HOMETASK_STATUS_INACTIVE,
      })
      this.$emit('onAssign', addedTask)
      this.display = false
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
