<template>
  <div class="p-1">
    <b-row class="align-items-center">
      <slot name="custom" />
      <b-col
        v-if="students.length"
        cols="12"
        md="6"
        xl="3"
      >
        <v-select
          id="subject"
          v-model="fl.student_id"
          :options="students"
          clearable
          label="username"
          :reduce="student => student.id"
          placeholder="Выберите ученика"
          class="d-block w-100 select-size-sm"
          @input="searchUpdated()"
        >
          <template #option="user">
            <span>{{ getStudentName(user) }}</span>
          </template>
          <template #selected-option="user">
            <span>{{ getStudentName(user) }}</span>
          </template>
          <template slot="no-options">
            {{ $t('components.select.no-items') }}
          </template>
        </v-select>
      </b-col>
      <b-col
        v-if="date"
        cols="12"
        md="6"
        xl="3"
      >
        <b-form-group class="mb-0">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr
              v-model="dateRange"
              class="form-control"
              :config="datePckrConfig"
              :placeholder="$t('table.header.date_picker_placeholder')"
            />
            <b-input-group-append
              class="cursor-pointer"
              is-text
              @click="dateRange = ''"
            >
              <feather-icon icon="XIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        v-if="statuses.length && statusesDropdown"
        cols
      >
        <v-select
          id="status"
          v-model="fl.statuses"
          :options="statuses"
          :reduce="status => status.value"
          placeholder="Все статусы"
          clearable
          class="d-block w-100 select-size-sm"
          @input="searchUpdated()"
          @clear="searchUpdated()"
        >
          <template slot="no-options">
            {{ $t('components.select.no-items') }}
          </template>
        </v-select>
      </b-col>
      <b-col
        v-else-if="statuses.length"
        cols
      >
        <b-form-radio-group
          v-model="fl.statuses"
          class="d-flex align-center"
          @change="searchUpdated()"
        >
          <b-form-radio
            v-for="status in statuses"
            :key="`status-${status.value}`"
            :value="status.value"
          >
            {{ status.label }}
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
      <b-col v-if="subjects">
        <v-select
          id="subjects"
          v-model="fl.subject_ids"
          :options="subjectsOptions"
          :reduce="status => status.id"
          placeholder="Все предметы"
          clearable
          label="text"
          class="d-block w-100 select-size-sm"
          @input="searchUpdated()"
          @clear="searchUpdated()"
        >
          <template slot="no-options">
            {{ $t('components.select.no-items') }}
          </template>
        </v-select>
      </b-col>
      <b-col v-if="studentType">
        <b-button-group>
          <b-button
            v-for="(type, typeKey) in studentTypes"
            :key="`studentType${typeKey}`"
            size="sm"
            :variant="fl.studentType === type.value ? 'primary' : 'outline-primary'"
            @click="() => studentTypeChanged(type.value)"
          >
            {{ type.label }}
          </b-button>
        </b-button-group>
      </b-col>
      <b-col
        cols
        class="d-none d-xl-flex"
      />
      <b-col
        v-if="search"
        cols="12"
        md="6"
        xl="4"
      >
        <b-form-group
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="filterInput"
              v-model="fl.search"
              type="search"
              :placeholder="$t('page.students.search.labels.input-search')"
              @keydown.enter="searchUpdated"
              @change="!fl.search ? searchUpdated() : ''"
            />
            <b-input-group-append>
              <b-button
                :disabled="!fl.search"
                variant="primary"
                @click="searchUpdated()"
              >
                {{ $t('page.students.search.labels.button-search') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BInputGroup, BFormInput, BFormGroup,
  BInputGroupAppend, BButton, BInputGroupPrepend,
  BFormRadioGroup, BFormRadio, BButtonGroup,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import { getUserFullName } from '@/helpers'

export default {
  name: 'TableHeader',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroupPrepend,
    flatPickr,
    vSelect,
    BFormRadioGroup,
    BFormRadio,
    BButtonGroup,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    searchUpdated: {
      type: Function,
      required: true,
    },
    search: {
      type: Boolean,
      default: false,
    },
    date: {
      type: Boolean,
      default: false,
    },
    students: {
      type: Array,
      default: () => [],
    },
    statuses: {
      type: Array,
      default: () => [],
    },
    statusesDropdown: {
      type: Boolean,
      default: false,
    },
    subjects: {
      type: Boolean,
      default: false,
    },
    subjectsMultiple: {
      type: Boolean,
      default: false,
    },
    studentType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateRange: '',
      fl: this.filter,
      datePckrConfig: {
        mode: 'range',
        locale: Russian,
        dateFormat: 'd.m.Y',
      },
    }
  },
  computed: {
    ...mapGetters({
      subjectsOptions: 'course/get_subjects',
    }),
    studentTypes() {
      return [
        {
          value: '',
          label: 'Все',
        },
        {
          value: 'lead',
          label: 'По заявкам',
        },
        {
          value: 'tutor',
          label: 'От репетитора',
        },
      ]
    },
  },
  watch: {
    dateRange: {
      handler() {
        if (!this.dateRange) {
          this.fl.date_range = ''
          this.searchUpdated()
          return
        }

        const dateRange = this.dateRange.split(' — ')
        if (dateRange.length === 2) {
          this.fl.date_range = dateRange.join(',')
          this.searchUpdated()
        }
      },
    },
  },
  mounted() {
    if (this.subjects) this.getSubjects()
  },
  methods: {
    ...mapActions({
      getSubjects: 'course/getSubjects',
    }),
    getStudentName(user) {
      return getUserFullName(user)
    },
    studentTypeChanged(type) {
      this.fl.studentType = type
      this.searchUpdated()
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
